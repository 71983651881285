import axios from 'axios';
import { to } from 'await-to-js';

const { REACT_APP_API_URL } = process.env;

export async function crearMarca(payload) {
    const [error, response] = await to(axios.post(`${REACT_APP_API_URL}/administrador/marcas/crear`, payload));

    if (error) {
        return {
            status: false,
            mensaje: 'Ocurrio un error al conectar con el servidor',
        };
    }

    return response.data;
}

export async function obtenerMarcas() {
    const [error, response] = await to(axios.get(`${REACT_APP_API_URL}/administrador/marcas/obtener`));

    if (error) {
        return {
            status: false,
            mensaje: 'Ocurrio un error al conectar con el servidor',
        };
    }

    return response.data;
}

export async function crearZonaNielzen(payload) {
    const [error, response] = await to(axios.post(`${REACT_APP_API_URL}/administrador/zonas-nielzen/crear`, payload));

    if (error) {
        return {
            status: false,
            mensaje: 'Ocurrio un error al conectar con el servidor',
        };
    }

    return response.data;
}

export async function obtenerZonasNielzen() {
    const [error, response] = await to(axios.get(`${REACT_APP_API_URL}/administrador/zonas-nielzen/obtener`));

    if (error) {
        return {
            status: false,
            mensaje: 'Ocurrio un error al conectar con el servidor',
        };
    }

    return response.data;
}

export async function crearProductoBP(payload) {
    const [error, response] = await to(axios.post(`${REACT_APP_API_URL}/administrador/productos-bp/crear`, payload));

    if (error) {
        return {
            status: false,
            mensaje: 'Ocurrio un error al conectar con el servidor',
        };
    }

    return response.data;
}

export async function obtenerProductosBP() {
    const [error, response] = await to(axios.get(`${REACT_APP_API_URL}/administrador/productos-bp/obtener`));

    if (error) {
        return {
            status: false,
            mensaje: 'Ocurrio un error al conectar con el servidor',
        };
    }

    return response.data;
}

export async function editarProductoBP(payload) {
    const [error, response] = await to(axios.post(`${REACT_APP_API_URL}/administrador/productos-bp/editar`, payload));

    if (error) {
        return {
            status: false,
            mensaje: 'Ocurrio un error al conectar con el servidor',
        };
    }

    return response.data;
}

export async function eliminarProductoBP(payload) {
    const [error, response] = await to(axios.post(`${REACT_APP_API_URL}/administrador/productos-bp/eliminar`, payload));

    if (error) {
        return {
            status: false,
            mensaje: 'Ocurrio un error al conectar con el servidor',
        };
    }

    return response.data;
}

export async function crearMarcasZonasProductosCSV(payload) {
    const [error, response] = await to(axios.post(`${REACT_APP_API_URL}/administrador/marcas-zonas-productos/crear-csv`, payload));

    if (error) {
        return {
            status: false,
            mensaje: 'Ocurrio un error al conectar con el servidor',
        };
    }

    return response.data;
}