import { Helmet } from 'react-helmet-async';
// @mui
import { Card, Stack, Container, Typography } from '@mui/material';

import SettingMode from './SettingMode';
import SettingColor from './SettingColor';

// ----------------------------------------------------------------------

export default function AdministradorConfiguracion() {
    return (
        <>
            <Helmet>
                <title> Configuración | Scorpion </title>
            </Helmet>

            <Container>
                <Typography variant="h4" sx={{ mb: 5 }}>
                    Configuración
                </Typography>

                <Card sx={{ p: 3 }}>
                    <Stack spacing={4} sx={{ pt: 3, px: 3, pb: 15 }}>
                        <Stack spacing={1.5}>
                            <Typography variant="subtitle2">Mode</Typography>
                            <SettingMode />
                        </Stack>

                        <Stack spacing={1.5}>
                            <Typography variant="subtitle2">Color</Typography>
                            <SettingColor />
                        </Stack>
                    </Stack>
                </Card>
            </Container>
        </>
    );
}
