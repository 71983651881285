import PropTypes from 'prop-types';
import { createContext, useReducer } from 'react';
// hooks

const initialState = {
    producto: {},
    onChangeProducto: () => {},
};

const handlers = {
    SETPRODUCTO: (state, action) => {
        const { producto } = action.payload;
        return {
            ...state,
            producto
        }
    }
}

const reducer = (state, action) => (handlers[action.type] ? handlers[action.type](state, action) : state);

const ProductoContext = createContext(initialState);

AdministradorProductoProvider.propTypes = {
    children: PropTypes.node,
};

function AdministradorProductoProvider({ children }) {
    const [state, dispatch] = useReducer(reducer, initialState);

    const onChangeProducto = (producto) => {
        dispatch({
            type: 'SETPRODUCTO',
            payload: { producto }
        });
    }

    return (
        <ProductoContext.Provider
            value={{
                ...state,
                onChangeProducto,
            }}
        >
            {children}
        </ProductoContext.Provider>
    );
}

export { AdministradorProductoProvider, ProductoContext };
