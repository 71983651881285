import { useState } from 'react';
import PropTypes from 'prop-types';
import {
    Grid,
    Typography,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Stack,
    TextField,
} from '@mui/material';
import { LoadingButton } from '@material-ui/lab';
import { enqueueSnackbar } from 'notistack';

import { crearZonaNielzen } from '../../../services/administrador/buscadorPrecios';

ModalFormZonaNielzen.propTypes = {
    open: PropTypes.bool,
    cerrarForm: PropTypes.func,
};

function ModalFormZonaNielzen({ open, cerrarForm }) {
    const [form, setForm] = useState({
        numero_zona: "",
        estado: "",
    });
    const [loadingBoton, setLoadingBoton] = useState(false);

    const handleChangeText = (e) => {
        const { name, value } = e.target;

        setForm({
            ...form,
            [name]: value,
        });
    }

    const crear = async () => {
        setLoadingBoton(true);
        // Checar que los campos no esten vacios
        let vacio = false;
        Object.keys(form).forEach((key) => {
            if (form[key] === "" || form[key] === null) {
                vacio = true;
            }
        });

        // Si estan vacios regresar
        if (vacio) {
            setLoadingBoton(false);
            enqueueSnackbar('Por favor introduzca los datos', { 
                variant: 'error',
                autoHideDuration: 2500,
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right'
                }
            });

            return;
        }

        const result = await crearZonaNielzen(form);

        setForm({});
        setLoadingBoton(false);
        cerrarForm();

        enqueueSnackbar(result.mensaje, { 
            variant: result.status ? 'success' : 'error',
            autoHideDuration: result.status ? 3000 : 10000,
            anchorOrigin: {
                vertical: 'top',
                horizontal: 'right'
            }
        });
    }

    return (
        <Dialog onClose={cerrarForm} open={open} maxWidth="sm" fullWidth>
            <DialogTitle>
                <Typography variant="h4" gutterBottom>
                    Crear Zona Nielzen
                </Typography>
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    Crear una nueva Zona Nielzen
                </DialogContentText>

                <Stack spacing={3} mt={2}>
                    <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                        <TextField
                            id="numero_zona"
                            name="numero_zona"
                            label="Número de Zona"
                            variant="outlined"
                            fullWidth
                            value={form.numero_zona}
                            onChange={handleChangeText}
                        />
                        <TextField
                            id="estado"
                            name="estado"
                            label="Estado"
                            variant="outlined"
                            fullWidth
                            value={form.estado}
                            onChange={handleChangeText}
                        />
                    </Stack>
                </Stack>

            </DialogContent>
            <DialogActions>
                <Button variant="outlined" onClick={cerrarForm}>Cancelar</Button>
                <LoadingButton variant="contained" loading={loadingBoton} onClick={crear}>
                    Crear
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
}

export default ModalFormZonaNielzen;
