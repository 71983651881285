import { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, LineChart, Line, PieChart, Pie, Cell } from 'recharts';
import _ from 'lodash';
// @mui
import {
    Card,
    CardHeader,
    CardContent,
    Grid,
    Stack,
    Button,
    Container,
    Typography,
    FormControl,
    Select,
    MenuItem,
    FormGroup,
    FormControlLabel,
    Checkbox
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { format } from 'date-fns';
// Traducir el picker date a español
import esEs from 'date-fns/locale/es';

// Servicios
import { obtenerAnalisisURL } from '../../../services/administrador/analisisURL';


export default function AdministradorAnalisisURL() {
    const [status, setStatus] = useState('');
    const [dataBase, setDataBase] = useState([]);
    const [monthlyStats, setMonthlyStats] = useState([]);
    const [smsStats, setSmsStats] = useState([]);
    const [totalStats, setTotalStats] = useState({});
    const [selectedPeriod, setSelectedPeriod] = useState('all');
    const [selectedMonths, setSelectedMonths] = useState([]);
    const [dateRange, setDateRange] = useState({
        from: new Date(),
        to: new Date()
    });

    // Filtrar datos según el período seleccionado el rango de fechas y por meses seleccionados
    const filterDataByPeriod = (data, period, range, months) => {
        const currentDate = new Date(); // Fecha final fija para simulación
        let startDate;

        // Definir fecha de inicio según el período seleccionado
        switch (period) {
            case 'last3':
                startDate = new Date(
                    new Date().getFullYear(),
                    new Date().getMonth() - 3, 
                    new Date().getDay()
                );
                break;
            case 'last6':
                startDate = new Date(
                    new Date().getFullYear(),
                    new Date().getMonth() - 6, 
                    new Date().getDay()
                );
                break;
            default:
                startDate = new Date('2024-12-01');
        }   

        // Si hay un rango de fechas seleccionado, usarlo en lugar del período predefinido
        if (format(range.from, 'yyyy-MM-dd') !== format(new Date(), 'yyyy-MM-dd') || format(range.to, 'yyyy-MM-dd') !== format(new Date(), 'yyyy-MM-dd')) {
            return data.filter(item => {
                const itemDate = new Date(item.month);
                return format(itemDate, 'yyyy-MM-dd') >= format(range.from, 'yyyy-MM-dd') && format(itemDate, 'yyyy-MM-dd') <= format(range.to, 'yyyy-MM-dd');
            });
        }

        // Filtrar por meses seleccionados usarlo cuando sea todo el periodo
        if (months.length > 0 && period === 'all') {
            return data.filter(item => {
                const itemDate = new Date(item.month);

                if (months.includes('00')) {
                    return itemDate >= new Date('2025-01-01') && itemDate <= new Date('2025-01-31') || months.includes(format(itemDate, 'MM'));
                }

                return months.includes(format(itemDate, 'MM'));
            });
        }

        // Filtrar por período predefinido si es all obtneer todos los datos hasta el momento
        return data.filter(item => {
            const itemDate = new Date(item.month);
            return format(itemDate, 'yyyy-MM-dd') >= format(startDate, 'yyyy-MM-dd') && format(itemDate, 'yyyy-MM-dd') <= format(currentDate, 'yyyy-MM-dd');
        });
    }

    const limpiarFiltros = () => {
        setSelectedPeriod('all');
        setSelectedMonths([]);
        setDateRange({
            from: new Date(),
            to: new Date()
        });
    }

    useEffect(() => {
        // Obtener los datos de la base de datos
        const obtenerDatos = async () => {
            const baseData = await obtenerAnalisisURL();

            if (baseData.status) {
                setDataBase(baseData.data);
                setStatus('OK');
            }
        };

        obtenerDatos();
    }, []);
    
    useEffect(() => {
        // Actualizar datos cuando cambie el período, el rango de fechas o los meses seleccionados
        if (status === 'OK') {
            const filteredData = filterDataByPeriod(dataBase, selectedPeriod, dateRange, selectedMonths);
            setMonthlyStats(filteredData);

            // Actualizar estadísticas SMS
            const smsData = filteredData.map(data => ({
                month: data.month,
                totalAccessed: data.smsVisits,
                averageEffectiveness: data.smsVisits !== 0 && data.totalVisits !== 0
                    ? parseFloat(((data.smsVisits / data.totalVisits) * 100).toFixed(1))
                    : parseFloat((0).toFixed(1))
            }));
            setSmsStats(smsData);

            // Actualizar totales
            setTotalStats({
                totalVisits: _.sumBy(filteredData, 'totalVisits'),
                totalSmsVisits: _.sumBy(filteredData, 'smsVisits'),
                totalNonSmsVisits: _.sumBy(filteredData, 'nonSmsVisits'),
                averageEffectiveness: (_.meanBy(smsData, 'averageEffectiveness')).toFixed(1)
            });
        }

    }, [status, selectedPeriod, dateRange, selectedMonths]);

    const COLORS = ['#021024', '#052659', '#548383', '#7DA0CA', '#C1E8FF'];

    return (
        <>
            <Helmet>
                <title> Análisis URL | Scorpion </title>
            </Helmet>

            <Container maxWidth="xl">
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                    <Typography variant="h4" gutterBottom>
                        Dashboard de Análisis de URLs
                    </Typography>
                </Stack>

                {/* Filtros de fecha */}
                {/* Selector de rango de fechas */}
                <Stack direction="row" alignItems="center" justifyContent="space-between">
                    <Stack direction="row" alignItems="center" justifyContent="flex-start" mb={3}>
                        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={esEs}>
                            <DatePicker value={dateRange.from} onChange={(newDate) => setDateRange(prev => ({ ...prev, from: newDate }))} />
                        </LocalizationProvider>
                        <Typography variant='body1' ml={2} mr={2}>hasta</Typography>
                        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={esEs}>
                            <DatePicker value={dateRange.to} onChange={(newDate) => setDateRange(prev => ({ ...prev, to: newDate }))} />
                        </LocalizationProvider>
                    </Stack>

                    <Stack direction="row" alignItems="center" justifyContent="flex-end">
                        <Button variant='outlined' color='error' style={{ marginRight: '15px' }} endIcon={<DeleteIcon />} onClick={limpiarFiltros}>
                            Limpiar Filtros
                        </Button>
                    </Stack>
                </Stack>

                {/* Selectores de período y meses */}
                {/* Selector de período predefinido */}
                <Grid style={{ padding: '15px' }}>
                    <Stack direction="row">
                        <Stack justifyContent="flex-start" mb={3} mr={2}>
                            <Typography variant='body1'>Período predefinido:</Typography>
                            <FormControl style={{ width: 250 }}>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={selectedPeriod}
                                    onChange={(e) => {setSelectedPeriod(e.target.value)}}
                                >
                                    <MenuItem value="all">Todo el período</MenuItem>
                                    <MenuItem value="last3">Últimos 3 meses</MenuItem>
                                    <MenuItem value="last6">Últimos 6 meses</MenuItem>
                                </Select>
                            </FormControl>
                        </Stack>
                        <Stack>
                            <Typography variant='body1' style={{ alignSelf: 'stretch' }}>Seleccionar meses:</Typography>
                            <FormGroup style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
                                {[
                                    {value: '00', label: 'Enero'},
                                    {value: '01', label: 'Febrero'},
                                    {value: '02', label: 'Marzo'},
                                    {value: '03', label: 'Abril'},
                                    {value: '04', label: 'Mayo'},
                                    {value: '05', label: 'Junio'},
                                ].map((month) => (
                                    <Stack>
                                        <FormControlLabel control={
                                            <Checkbox
                                                value={month.value}
                                                checked={selectedMonths.includes(month.value)}
                                                onChange={(e) => {
                                                    const value = e.target.value;
                                                    setSelectedMonths(prev =>
                                                        e.target.checked
                                                        ? [...prev, value]
                                                        : prev.filter(m => m !== value)
                                                    );
                                                }}
                                        />} label={month.label} />
                                    </Stack>
                                    )
                                )}
                            </FormGroup>

                            <FormGroup style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
                                {[
                                    {value: '06', label: 'Julio'},
                                    {value: '07', label: 'Agosto'},
                                    {value: '08', label: 'Septiembre'},
                                    {value: '09', label: 'Octubre'},
                                    {value: '10', label: 'Noviembre'},
                                    {value: '11', label: 'Diciembre'}
                                ].map((month) => (
                                    <Stack>
                                        <FormControlLabel control={
                                            <Checkbox
                                                value={month.value}
                                                checked={selectedMonths.includes(month.value)}
                                                onChange={(e) => {
                                                    const value = e.target.value;
                                                    setSelectedMonths(prev =>
                                                        e.target.checked
                                                        ? [...prev, value]
                                                        : prev.filter(m => m !== value)
                                                    );
                                                }}
                                        />} label={month.label} />
                                    </Stack>
                                    )
                                )}
                            </FormGroup>
                        </Stack>
                    </Stack>
                </Grid>

                {/* Resumen */}
                <Grid style={{ display: 'flex', justifyContent: 'space-around', marginBottom: '20px' }}>
                    <Card style={{ padding: '20px', width: '300px' }}>
                        <Typography variant='body1'>
                            Total de Visitas
                        </Typography>

                        {Object.keys(totalStats).length !== 0 && (
                            <>
                                <Typography variant='h4'>
                                    {totalStats.totalVisits?.toLocaleString()}
                                </Typography>
                                <Typography variant='body1'>
                                    Periodo seleccionado
                                </Typography>
                            </>
                        )}
                    </Card>

                    <Card style={{ padding: '20px', width: '300px' }}>
                        <Typography variant='body1'>
                            Visitas por SMS
                        </Typography>

                        {Object.keys(totalStats).length !== 0 && (
                            <>
                                <Typography variant='h4'>
                                    {totalStats.totalSmsVisits?.toLocaleString()}
                                </Typography>
                                <Typography variant='body1'>
                                    Mensajes con SMS
                                </Typography>
                            </>
                        )}
                    </Card>

                    <Card style={{ padding: '20px', width: '300px' }}>
                        <Typography variant='body1'>
                            Visitas Organicas
                        </Typography>

                        {Object.keys(totalStats).length !== 0 && (
                            <>
                                <Typography variant='h4'>
                                    {totalStats.totalNonSmsVisits?.toLocaleString()}
                                </Typography>
                                <Typography variant='body1'>
                                    Sin mensaje SMS
                                </Typography>
                            </>
                        )}
                    </Card>

                    <Card style={{ padding: '20px', width: '300px' }}>
                        <Typography variant='body1'>
                            Efectividad Promedio
                        </Typography>

                        {Object.keys(totalStats).length !== 0 && (
                            <>
                                <Typography variant='h4'>
                                    {totalStats.averageEffectiveness}%
                                </Typography>
                                <Typography variant='body1'>
                                    De campañas SMS
                                </Typography>
                            </>
                        )}
                    </Card>
                </Grid>

                {/* Gráfico de Visitas Mensuales */}
                <Card style={{ marginBottom: '20px' }}>
                    <CardHeader title="Visitas Mensuales" />
                    <CardContent>
                        <BarChart data={monthlyStats} width={1400} height={400}>
                        <CartesianGrid strokeDasharray="3 3" stroke="#f5f5f5" />
                        <XAxis dataKey="month" />
                        <YAxis />
                        <Tooltip />
                        <Legend />
                        <Bar dataKey="smsVisits" name="Visitas por SMS" fill="#052659" />
                        <Bar dataKey="nonSmsVisits" name="Visitas sin SMS" fill="#7DA0CA" />
                        </BarChart>
                    </CardContent>
                </Card>

                {/* Gráfico Efectividad de Campañas SMS */}
                <Card style={{ marginBottom: '20px' }}>
                    <CardHeader title="Efectividad de Campañas SMS" />
                    <CardContent>
                            <LineChart data={smsStats} width={1400} height={400}>
                            <CartesianGrid strokeDasharray="3 3" stroke="#f5f5f5" />
                            <XAxis dataKey="month" />
                            <YAxis yAxisId="left" />
                            <YAxis yAxisId="right" orientation="right" />
                            <Tooltip />
                            <Legend />
                            <Line 
                                yAxisId="left"
                                type="monotone" 
                                dataKey="totalAccessed" 
                                name="Total Accesos" 
                                stroke="#052659" 
                                strokeWidth={2}
                            />
                            <Line 
                                yAxisId="right"
                                type="monotone" 
                                dataKey="effectivenessRate" 
                                name="Tasa de Efectividad (%)" 
                                stroke="#7DA0CA" 
                                strokeWidth={2}
                            />
                            </LineChart>
                    </CardContent>
                </Card>

                {/* Distribution Charts */}
                <Card style={{ marginBottom: '20px' }}>
                    <CardHeader title="Distribución de Visitas" />
                    <CardContent>
                        <PieChart width={1400} height={400}>
                            <Pie
                            data={[
                                { name: 'Visitas por SMS', value: totalStats.totalSmsVisits },
                                { name: 'Visitas sin SMS', value: totalStats.totalNonSmsVisits }
                            ]}
                            cx="50%"
                            cy="50%"
                            labelLine={false}
                            label={({name, percent}) => `${name}: ${(percent * 100).toFixed(1)}%`}
                            outerRadius={80}
                            fill="#8884d8"
                            dataKey="value"
                            >
                            {[0, 1].map((entry, index) => (
                                <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                            ))}
                            </Pie>
                            <Tooltip />
                        </PieChart>
                    </CardContent>
                </Card>

                <Card>
                    <CardHeader title="Tendencia Mensual de Efectividad" />
                    <CardContent>
                        <LineChart data={monthlyStats} width={1400} height={400}>
                            <CartesianGrid strokeDasharray="3 3" stroke="#f5f5f5" />
                            <XAxis dataKey="month" />
                            <YAxis domain={[0, 100]} />
                            <Tooltip />
                            <Legend />
                            <Line 
                                type="monotone" 
                                dataKey="smsPercentage" 
                                name="Efectividad SMS (%)" 
                                stroke="#052659" 
                                strokeWidth={2}
                            />
                        </LineChart>
                    </CardContent>
                </Card>
            </Container>
        </>
    );
}
