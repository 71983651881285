import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

// material
import { LoadingButton } from '@material-ui/lab';
import {
    Box,
    Card,
    Container,
    Button,
    Grid,
    Stack,
    TextField,
    Typography,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
} from '@material-ui/core';
import { FileUploader } from 'react-drag-drop-files';
import { SnackbarProvider, enqueueSnackbar } from 'notistack';

// import ColorPicker from '../../../components/color-picker/ColorPicker';

// context
import useProducto from '../../../hooks/administrador/useProducto';

// services
import { crearProductoBP, obtenerMarcas, obtenerZonasNielzen, editarProductoBP } from '../../../services/administrador/buscadorPrecios';


const {
    REACT_APP_API_URL_IMAGES
} = process.env;

const fileTypes = ["png", "jpg", "jpeg"];

// ----------------------------------------------------------------------

FormProducto.propTypes = {
    isEdit: PropTypes.bool,
    producto: PropTypes.object
};

export default function FormProducto({ isEdit, producto }) {
    const navigate = useNavigate();
    const { onChangeProducto } = useProducto();
    const [loadingBoton, setLoadingBoton] = useState(false);
    const [form, setForm] = useState({
        id_marca: "",
        sku: "",
        descripcion: "",
        marca: "",
        cod_barras: "",
        precio_pza: "",
        margen: "",
        precio_sug: "",
        zona_nielzen: "",
    });
    const [imagen, setImagen] = useState({
        nombre: "",
        archivo: "",
    });
    const [marcas, setMarcas] = useState([]);
    const [zonasNielzen, setZonasNielzen] = useState([]);

    useEffect(() => {
        if (Object.keys(producto).length !== 0) {
            setForm({
                id_marca: producto.id_marca,
                sku: producto.sku,
                descripcion: producto.descripcion,
                marca: producto.marca,
                cod_barras: producto.cod_barras,
                precio_pza: producto.precio_pza,
                margen: producto.margen,
                precio_sug: producto.precio_sug,
                zona_nielzen: producto.zona_nielzen,
            });

            setImagen({
                nombre: producto.imagen,
                archivo: producto.imagen,
            })
        }
    }, [producto]);

    useEffect(() => {
        obtenerDatos();
    }, []);

    // Obtner las Marcas y las Zonas Nielzen de la Base de Datos
    const obtenerDatos = async () => {
        const marcasBD = await obtenerMarcas();
        const zonasNielzenBD = await obtenerZonasNielzen();

        setMarcas(marcasBD.data);
        setZonasNielzen(zonasNielzenBD.data);
    }

    const handleChangeText = (e) => {
        const { name, value } = e.target;

        setForm({
            ...form,
            [name]: value,
        });
    }

    const selectFile = (event) => {
        setImagen({
            nombre: event.name,
            archivo: event,
        });
    }

    const cancelar = () => {
        onChangeProducto({});
        navigate('/administrador/productos-buscador-precios', { replace: true });
    }

    const crearEditarProducto = async () => {
        setLoadingBoton(true);

        // Poner el Nombre de la Marca
        marcas.forEach((marca) => {
            if (marca.id === form.id_marca) {
                form.marca = marca.marca;
            }
        });

        // Checar que los campos no esten vacios
        let vacio = false;
        Object.keys(form).forEach((key) => {
            if (form[key] === "" || form[key] === null) {
                vacio = true;
            }
        });

        // Si estan vacios regresar
        if (vacio) {
            setLoadingBoton(false);
            enqueueSnackbar('Por favor introduzca los datos', { 
                variant: 'error',
                autoHideDuration: 2500,
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right'
                }
            });

            return;
        }

        const data = new FormData();
        Object.keys(form).forEach((key) => {
            data.append(key, form[key]);
        });

        if (imagen.archivo) {
            data.append("imagen", imagen.archivo);
        } else {
            setLoadingBoton(false);
            enqueueSnackbar('Por favor seleccione una imagen', {
                variant: 'error',
                autoHideDuration: 2500,
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right'
                }
            });
        }

        let result = {}
        
        if (!isEdit) {
            result = await crearProductoBP(data);
        } else {
            result = await editarProductoBP(data);
        }
        
        setLoadingBoton(false);
        enqueueSnackbar(result.mensaje, { 
            variant: result.status ? 'success' : 'error',
            autoHideDuration: 2500,
            anchorOrigin: {
                vertical: 'top',
                horizontal: 'right'
            }
        });

        if (result.status) {
            onChangeProducto({});
            setTimeout(() => {
                navigate('/administrador/productos-buscador-precios', { replace: true });
            }, 3000);
        }
    }

    return (
        <Container>
            {/* Alerta */}
            <SnackbarProvider 
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            />

            <Typography variant="h4" mb={3}>
                {isEdit ? 'Editar Producto' : 'Crear Producto'}
            </Typography>

            <Grid container spacing={3}>
                <Grid item xs={12} md={12}>
                    <Card sx={{ p: 3 }}>
                        <Stack spacing={3}>
                            <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                                <TextField
                                    id="sku"
                                    name="sku"
                                    label="SKU"
                                    variant="outlined"
                                    fullWidth
                                    value={form.sku}
                                    onChange={handleChangeText}
                                />
                                <TextField
                                    id="descripcion"
                                    name="descripcion"
                                    label="Descripción"
                                    variant="outlined"
                                    fullWidth
                                    value={form.descripcion}
                                    onChange={handleChangeText}
                                />
                            </Stack>

                            <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                                <FormControl fullWidth>
                                    <InputLabel id="simple-marca-label">Marca</InputLabel>
                                    <Select
                                        labelId="simple-marca-label"
                                        id="id_marca"
                                        name="id_marca"
                                        label="Marca"
                                        value={form.id_marca}
                                        onChange={handleChangeText}
                                    >
                                        {
                                            marcas.map((marca) => (
                                                <MenuItem key={marca.id} value={marca.id}>{marca.marca}</MenuItem>
                                            ))
                                        }
                                    </Select>
                                </FormControl>
                                <TextField
                                    id="cod_barras"
                                    name="cod_barras"
                                    label="Código de Barras"
                                    variant="outlined"
                                    fullWidth
                                    value={form.cod_barras}
                                    onChange={handleChangeText}
                                />
                            </Stack>

                            <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                                <TextField
                                    id="precio_pza"
                                    name="precio_pza"
                                    label="Precio Pieza"
                                    variant="outlined"
                                    fullWidth
                                    value={form.precio_pza}
                                    onChange={handleChangeText}
                                />
                                <TextField
                                    id="margen"
                                    name="margen"
                                    label="Margen"
                                    variant="outlined"
                                    fullWidth
                                    value={form.margen}
                                    onChange={handleChangeText}
                                />
                            </Stack>

                            <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                                <TextField
                                    id="precio_sug"
                                    name="precio_sug"
                                    label="Precio Sugerido"
                                    variant="outlined"
                                    fullWidth
                                    value={form.precio_sug}
                                    onChange={handleChangeText}
                                />
                                <FormControl fullWidth>
                                    <InputLabel id="simple-zona-nielzen-label">Zona Nielzen</InputLabel>
                                    <Select
                                        labelId="simple-zona-nielzen-label"
                                        id="zona_nielzen"
                                        name="zona_nielzen"
                                        label="zona_nielzen"
                                        value={form.zona_nielzen}
                                        onChange={handleChangeText}
                                    >
                                        {
                                            zonasNielzen.map((zona) => (
                                                <MenuItem key={zona.id} value={zona.id}>{`${zona.numero_zona} - ${zona.estado}`}</MenuItem>
                                            ))
                                        }
                                    </Select>
                                </FormControl>
                            </Stack>

                            <Grid item container justifyContent="center" mt={5}>
                                <FileUploader handleChange={(e) => selectFile(e)} name="file" types={fileTypes} />
                            </Grid>
                            <Grid container item justifyContent="center">
                                {
                                    imagen.archivo
                                    ? <img key="Imagen" src={imagen.archivo instanceof Object ? URL.createObjectURL(imagen.archivo) : `${REACT_APP_API_URL_IMAGES}${imagen.archivo}`} style={{ height: 200, width: 200 }} alt="Imagen Producto"/>
                                    : ''
                                }
                            </Grid>

                            <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-end' }}>
                                <Button
                                    style={{ marginRight: '15px' }}
                                    variant="outlined"
                                    onClick={cancelar}
                                >
                                    Cancelar
                                </Button>
                                <LoadingButton variant="contained" loading={loadingBoton} onClick={crearEditarProducto}>
                                    {!isEdit ? 'Crear Producto' : 'Guardar Cambios'}
                                </LoadingButton>
                            </Box>
                        </Stack>
                    </Card>
                </Grid>
            </Grid>
        </Container>
    );
}
