import { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';
import { filter } from 'lodash';
// @mui
import {
    Card,
    Table,
    Grid,
    Stack,
    Paper,
    Button,
    Popover,
    TableRow,
    MenuItem,
    TableBody,
    TableCell,
    Container,
    Typography,
    IconButton,
    TableContainer,
    TablePagination,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
} from '@mui/material';
import SvgIcon from '@mui/material/SvgIcon';

import { LoadingButton } from '@material-ui/lab';
import { SnackbarProvider, enqueueSnackbar } from 'notistack';

// components
import LoadingScreen from '../../../components/loading/LoadingScreen';
import Iconify from '../../../components/iconify';
import Scrollbar from '../../../components/scrollbar';

// context
import useProducto from '../../../hooks/administrador/useProducto';

import CabeceraTabla from './CabeceraTabla';

// forms
import BuscadorProductos from './BuscadorProductos';
import ModalFormMarca from './ModalFormMarca';
import ModalFormZonaNielzen from './ModalFormZonaNielzen';
import ModalFormArchivo from './ModalFormArchivo';

// Servicios
import { obtenerProductosBP, eliminarProductoBP as eliminar } from '../../../services/administrador/buscadorPrecios';

const {
    REACT_APP_API_URL_IMAGES_PRODUCTOS,
    REACT_APP_API_URL_IMAGES
} = process.env;

// ----------------------------------------------------------------------

const TABLE_HEAD = [
    { id: 'imagen', label: '', alignRight: false },
    { id: 'sku', label: 'SKU', alignRight: false },
    { id: 'descripcion', label: 'Descripción', alignRight: false },
    { id: 'marca', label: 'Marca', alignRight: false },
    { id: 'cod_barras', label: 'Código de Barras', alignRight: false },
    { id: 'precio_pza', label: 'Precio Pieza', alignRight: true },
    { id: 'margen', label: 'Margen', alignRight: true },
    { id: 'precio_sug', label: 'Precio Sugerido', alignRight: true },
    { id: 'zona_nielzen', label: 'Zona Nielzen', alignRight: false },
    { id: '' },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    if (query) {
        return filter(array, (_user) => _user.descripcion.toLowerCase().indexOf(query.toLowerCase()) !== -1);
    }
    return stabilizedThis.map((el) => el[0]);
}

// ----------------------------------------------------------------------
// Icono de Excel
function ExcelIcon(props) {
    return (
        <SvgIcon {...props}>
            <svg 
                version="1.0"
                xmlns="http://www.w3.org/2000/svg"
                width="18.000000pt"
                height="18.000000pt" 
                viewBox="0 0 50.000000 50.000000"
                preserveAspectRatio="xMidYMid meet"
            >
                <g transform="translate(0.000000,50.000000) scale(0.100000,-0.100000)"
                fill="#FFF" stroke="none">
                    <path d="M135 471 l-130 -26 0 -195 0 -195 135 -27 c74 -15 141 -27 148 -27 9
                    -1 12 55 12 249 0 232 -1 250 -17 249 -10 -1 -76 -13 -148 -28z m1 -163 l17
                    -33 15 33 c11 23 22 32 39 32 l24 0 -26 -45 -26 -44 26 -46 27 -45 -24 0 c-16
                    0 -30 10 -43 32 l-18 32 -16 -32 c-10 -22 -22 -32 -38 -32 -29 0 -29 2 3 50
                    l26 39 -26 46 -27 45 25 0 c19 0 30 -9 42 -32z"/>
                    <path d="M320 406 c0 -19 6 -37 13 -40 10 -5 10 -7 0 -12 -7 -3 -13 -16 -13
                    -29 0 -13 6 -26 13 -29 10 -5 10 -7 0 -12 -7 -3 -13 -16 -13 -29 0 -13 6 -26
                    13 -29 10 -5 10 -7 0 -12 -7 -3 -13 -18 -13 -34 0 -16 6 -31 13 -34 10 -5 10
                    -7 0 -12 -7 -3 -13 -21 -13 -40 l0 -35 83 3 82 3 0 185 0 185 -82 3 -83 3 0
                    -35z m120 -46 c0 -5 -18 -10 -40 -10 -22 0 -40 5 -40 10 0 6 18 10 40 10 22 0
                    40 -4 40 -10z m0 -70 c0 -5 -18 -10 -40 -10 -22 0 -40 5 -40 10 0 6 18 10 40
                    10 22 0 40 -4 40 -10z m0 -70 c0 -5 -18 -10 -40 -10 -22 0 -40 5 -40 10 0 6
                    18 10 40 10 22 0 40 -4 40 -10z m0 -80 c0 -5 -18 -10 -40 -10 -22 0 -40 5 -40
                10 0 6 18 10 40 10 22 0 40 -4 40 -10z"/>
                </g>
            </svg>
        </SvgIcon>
    );
}

export default function AdministradorProductosBuscadorPrecios() {
    const { onChangeProducto } = useProducto();
    const navigate = useNavigate();
    const [productos, setProductos] = useState([]);
    const [producto, setProducto] = useState({});
    const [open, setOpen] = useState(null);
    const [openModalFormMarca, setOpenModalFormMarca] = useState(false);
    const [openModalFormZonaNielzen, setOpenModalFormZonaNielzen] = useState(false);
    const [openModalArchivo, setOpenModalArchivo] = useState(false);
    const [tipo, setTipo] = useState('');
    const [titulo, setTitulo] = useState('');
    const [page, setPage] = useState(0);
    // eslint-disable-next-line
    const [order, setOrder] = useState('asc');
    // eslint-disable-next-line
    const [orderBy, setOrderBy] = useState('name');
    const [filterName, setFilterName] = useState('');
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [openEliminar, setOpenEliminar] = useState(false);
    const [loadingBoton, setLoadingBoton] = useState(false);
    const [loading, setLoading] = useState();

    useEffect(() => {
        getProductos();
    }, []);

    const getProductos = async () => {
        setLoading(true);
        const result = await obtenerProductosBP();
        
        if (result.status) {
            setProductos(result.data);
        }
        setLoading(false);
    };

    const handleOpenMenu = (event, data) => {
        setProducto(data);
        setOpen(event.currentTarget);
    };

    const handleCloseMenu = () => {
        setProducto({});
        setOpen(null);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setPage(0);
        setRowsPerPage(parseInt(event.target.value, 10));
    };

    const handleFilterByName = (event) => {
        setPage(0);
        setFilterName(event.target.value);
    };

    const crearProducto = () => {
        navigate('/administrador/productos-buscador-precios/crear', { replace: true });
    }

    const editarProducto = () => {
        onChangeProducto(producto);
        navigate('/administrador/productos-buscador-precios/editar', { replace: true });
    }

    const abrirModalFormMarca = () => {
        setOpenModalFormMarca(true);
    }

    const cerrarModalFormMarca = () => {
        setOpenModalFormMarca(false);
    }

    const abrirModalFormZonaNielzen = () => {
        setOpenModalFormZonaNielzen(true);
    }

    const cerrarModalFormZonaNielzen = () => {
        setOpenModalFormZonaNielzen(false);
    }

    const abrirModalFormArchivo = (tipo, titulo) => {
        setTipo(tipo);
        setTitulo(titulo);
        setOpenModalArchivo(true);
    }

    const cerrarVentanaEliminarModalFormArchivo = () => {
        setTipo("");
        setTitulo("");
        setOpenModalArchivo(false);
    }

    const abrirVentanaEliminar = () => {
        setOpen(null);
        setOpenEliminar(true);
    }

    const cerrarVentanaEliminar = () => {
        setOpen(null);
        setOpenEliminar(false);
    }

    const eliminarProducto = async () => {
        setLoadingBoton(true);
        
        const result = await eliminar(producto);

        if (result.status) {
            getProductos();
        }

        setLoadingBoton(false);
        setOpenEliminar(false);

        enqueueSnackbar(result.mensaje, { 
            variant: result.status ? 'success' : 'error',
            autoHideDuration: 3000,
            anchorOrigin: {
                vertical: 'top',
                horizontal: 'right'
            }
        });
    }

    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - productos.length) : 0;
    const filteredUsers = applySortFilter(productos, getComparator(order, orderBy), filterName);
    const isNotFound = !filteredUsers.length && !!filterName;

    return (
        <>
            <Helmet>
                <title> Productos BP | Scorpion </title>
            </Helmet>
            {/* Alerta */}
            <SnackbarProvider 
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            />

            {/* Venta de eliminar Producto */}
            <Dialog onClose={cerrarVentanaEliminar} open={openEliminar} maxWidth="sm" fullWidth>
                <DialogTitle>
                    <Typography variant="h4" gutterBottom>
                        Eliminar Producto
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        ¿Estás seguro de eliminar el Producto?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" onClick={cerrarVentanaEliminar}>Cancelar</Button>
                    <LoadingButton variant="contained" color="error" loading={loadingBoton} onClick={eliminarProducto}>
                        Eliminar
                    </LoadingButton>
                </DialogActions>
            </Dialog>

            {/* Modal Form Marca */}
            <ModalFormMarca open={openModalFormMarca} cerrarForm={cerrarModalFormMarca} />

            {/* Modal Form Zona Nielzen */}
            <ModalFormZonaNielzen open={openModalFormZonaNielzen} cerrarForm={cerrarModalFormZonaNielzen} />

            {/* Modal Form para subir Archivos */}
            <ModalFormArchivo open={openModalArchivo} cerrarForm={cerrarVentanaEliminarModalFormArchivo} tipo={tipo} titulo={titulo} />

            <Container maxWidth="xl">
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                    <Typography variant="h4" gutterBottom>
                        Productos para Buscador de Precios
                    </Typography>
                    <Stack direction="row" alignItems="center" justifyContent="flex-end">
                    <Button variant='contained' startIcon={<ExcelIcon />} style={{ marginRight: '15px' }} onClick={() => abrirModalFormArchivo("Producto", "Archivo para guardar los Productos")}>
                        Subir Productos
                    </Button>
                    <Button variant='contained' startIcon={<ExcelIcon />} style={{ marginRight: '15px' }} onClick={() => abrirModalFormArchivo("Marca", "Archivo para guardar las Marcas")}>
                        Subir Marcas
                    </Button>
                    <Button variant='contained' startIcon={<ExcelIcon />} onClick={() => abrirModalFormArchivo("Zona", "Archivo para guardar las Zonas Nielzen")}>
                        Subir Zonas Nielzen
                    </Button>
                    </Stack>
                </Stack>

                <Card>
                    <Grid style={{ padding: '15px' }}>
                        <Stack direction="row" alignItems="center" justifyContent="space-between">
                            <Stack direction="row" alignItems="center" justifyContent="flex-start">
                                <BuscadorProductos filterName={filterName} onFilterName={handleFilterByName} />
                            </Stack>
                            <Stack direction="row" alignItems="center" justifyContent="flex-end">
                                <Button variant="contained" startIcon={<Iconify icon="eva:plus-fill" />} style={{ marginRight: '15px' }} onClick={crearProducto}>
                                    Nuevo Producto
                                </Button>
                                <Button variant='contained' startIcon={<Iconify icon="eva:plus-fill" />} style={{ marginRight: '15px' }} onClick={abrirModalFormMarca}>
                                    Nueva Marca
                                </Button>
                                <Button variant='contained' startIcon={<Iconify icon="eva:plus-fill" />} onClick={abrirModalFormZonaNielzen}>
                                    Nueva Zona Nielzen
                                </Button>
                            </Stack>
                        </Stack>
                    </Grid>

                    <Scrollbar>
                        <TableContainer sx={{ minWidth: 800 }}>
                            <Table>
                                <CabeceraTabla headLabel={TABLE_HEAD} />
                                <TableBody>
                                    {filteredUsers
                                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                        // eslint-disable-next-line
                                        .map((producto) => {
                                            const { imagen, sku, descripcion, marca, cod_barras, precio_pza, margen, precio_sug, zona } = producto;

                                            return (
                                                <TableRow hover key={producto.pk}>
                                                    <TableCell align="center">
                                                        <img
                                                            src={imagen !== null ? `${REACT_APP_API_URL_IMAGES_PRODUCTOS}${imagen}` : `${REACT_APP_API_URL_IMAGES}public/producto_bp.jpg`}
                                                            alt="Imagen"
                                                            width="50" height="50"
                                                        />
                                                    </TableCell>
                                                    <TableCell align="left">
                                                        <Typography variant="subtitle2" color="text.primary">
                                                            {sku}
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell align="left">
                                                        <Typography variant="subtitle2" color="text.primary">
                                                            {descripcion}
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell align="left">
                                                        <Typography variant="subtitle2" color="text.primary">
                                                            {marca}
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell align="left">
                                                        <Typography variant="subtitle2" color="text.primary">
                                                            {cod_barras}
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell align="right">
                                                        <Typography variant="subtitle2" color="text.primary">
                                                            ${precio_pza}
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell align="right">
                                                        <Typography variant="subtitle2" color="text.primary">
                                                            {margen}
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell align="right">
                                                        <Typography variant="subtitle2" color="text.primary">
                                                            ${precio_sug}
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell align="left">
                                                        <Typography variant="subtitle2" color="text.primary">
                                                            {zona}
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell align="right">
                                                        <IconButton
                                                            size="large"
                                                            color="inherit"
                                                            onClick={(e) => handleOpenMenu(e, producto)}
                                                        >
                                                            <Iconify icon={'eva:more-vertical-fill'} />
                                                        </IconButton>
                                                    </TableCell>
                                                </TableRow>
                                            );
                                        })}
                                    {emptyRows > 0 && (
                                        <TableRow style={{ height: 77 * emptyRows }}>
                                            <TableCell colSpan={6} />
                                        </TableRow>
                                    )}
                                </TableBody>

                                {isNotFound && (
                                    <TableBody>
                                        <TableRow>
                                            <TableCell align="center" colSpan={9} sx={{ py: 3 }}>
                                                <Paper
                                                    sx={{
                                                        textAlign: 'center',
                                                    }}
                                                >
                                                    <Typography variant="h6" paragraph>
                                                        No encontrado
                                                    </Typography>

                                                    <Typography variant="body2">
                                                        No hay resultados para &nbsp;
                                                        <strong>&quot;{filterName}&quot;</strong>.
                                                        <br /> Intente con otro producto.
                                                    </Typography>
                                                </Paper>
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                )}

                                {loading && (
                                    <TableBody>
                                        <TableRow>
                                            <TableCell align="center" colSpan={9} sx={{ py: 9 }}>
                                                <Paper
                                                    sx={{
                                                        textAlign: 'center',
                                                    }}
                                                >
                                                    <LoadingScreen />
                                                </Paper>
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                )}
                            </Table>
                        </TableContainer>
                    </Scrollbar>

                    <TablePagination
                        rowsPerPageOptions={[5, 10]}
                        component="div"
                        count={filteredUsers.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Card>
            </Container>

            <Popover
                open={Boolean(open)}
                anchorEl={open}
                onClose={handleCloseMenu}
                anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                PaperProps={{
                    sx: {
                        p: 1,
                        width: 180,
                        '& .MuiMenuItem-root': {
                            px: 1,
                            typography: 'body2',
                            borderRadius: 0.75,
                        },
                    },
                }}
            >
                <MenuItem onClick={editarProducto}>
                    <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
                    Editar Producto
                </MenuItem>

                <MenuItem onClick={abrirVentanaEliminar} sx={{ color: 'error.main' }}>
                    <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />
                    Eliminar Producto
                </MenuItem>
            </Popover>
        </>
    );
}
