import { useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
// material
import { Container } from '@material-ui/core';

// context
import useProducto from '../../../hooks/administrador/useProducto';

// components
import FormProducto from './FormProducto';

// ----------------------------------------------------------------------

export default function CrearEditarProducto() {
    const { producto } = useProducto();
    const { pathname } = useLocation();
    const isEdit = pathname.includes('editar');
    const currentUser = producto;

    return (
        <>
            <Helmet>
                <title> {!isEdit ? 'Crear Producto' : 'Editar Producto'} | Scorpion </title>
            </Helmet>
        
            <Container maxWidth="xl">
                <FormProducto isEdit={isEdit} producto={currentUser} />
            </Container>
        </>
    );
}
